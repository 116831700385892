@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  font-family: 'YourCustomFont' !important;
                                                

}

/* src/fonts/YourCustomFont.css */
@font-face {
  font-family: 'YourCustomFont';
  src: url('./Fonts/ITCMachineMedium.otf') format('opentype');
  /* You can also specify font-weight, font-style, etc. */
}

.bg {


  background-repeat: no-repeat !important;
  background-size: cover !important;
    background: #00000091 url('./b2.gif') ;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: center !important;
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    z-index: 1111;
  }
  .pageCon {
    background: rgb(87,87,87);
    background: linear-gradient(0deg,#1140aa 0%, #113faae9 46%,  #113faaf1 86%);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    overflow: hidden;
    right: 0;
    top: 0;
  }


  .title{
    font-size: 45px;
    color: #fff;
    text-align: center;

  
  }
  @media screen and (min-width: 1000px){
    .title{
      font-size: 80px;
      color: #fff;
      text-align: start;
    
    }
  }
  .footer_title{
    font-size: 40px;
    color: #000000;
    
  }
  @media screen and (min-width: 1000px){
    .footer_title{
      font-size: 70px;
      color: #000000;
    }
  }
  

  .titleunder{
    font-size: 35px;
    color: #e89e14;

  }


  .moveelement_last{
    justify-content: end;
    align-items: end;
    text-align: end;
   
  }

.shadow{
  filter: drop-shadow(0 0 10px rgba(240, 158, 42, 0.682));
}

.first_marque {
  background: linear-gradient(0deg, rgb(255, 174, 0) 0%, rgb(255, 174, 0) 100%);
  color: #113faaf1;
  height: 10vh;
  transform: rotate(4deg) !important;
}
.two_marque {
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
  color: #113faaf1;
  height: 10vh;
  transform: rotate(-4deg) !important;
}

.gib_title{
  color: #fff !important;
  font-size: 40px !important;
  text-align: center;
}
@media screen and (min-width: 1000px){
  .gib_title{
    color: #fff !important;
    font-size: 60px !important;
    text-align: start;
  }
}

.gib_para{
  color: #fff !important;
  font-size: 20px !important;
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
}
@media screen and (min-width: 1000px){
  .gib_para{
    color: #fff !important;
    font-size: 25px !important;
    text-align: start;
    font-family: 'Poppins', sans-serif !important;
  }
}

.title_des{
  color: #fff !important;
  font-size: 40px !important;
  
}



.title_para{
  color: #fff !important;
  font-size: 20px !important;
}


.moveelement_center{
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
 
}


.under_title{
  color: #ff8c00;
  font-size: 20px;
  text-align: center;
 
}

@media screen and (min-width: 1000px){
  .under_title{
    color: #ff8c00;
    font-size: 30px;
    text-align: start;
 
  }
}

.under_title_ca{
  color: #ffffff;
  font-family: 'Poppins', sans-serif !important;
  font-size: 12px;
text-align: center;
}

@media screen and (min-width: 1000px){
  .under_title_ca{
    color: #ffffff;
    font-family: 'Poppins', sans-serif !important;
    font-size: 20px;
  text-align: center;
  }
}










/* CSS */
.button-88 {

  align-items: center;
 
  font-weight: 500;
  font-size: 16px;
  padding: 0.7em 1.4em 0.7em 1.1em;
  color: rgb(0, 0, 0);
  background: #ad5389;
  background: linear-gradient(0deg, rgba(20,167,62,1) 0%, rgba(102,247,113,1) 100%);
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #14a73e98;
  letter-spacing: 0.05em;
  border-radius: 20em;
  cursor: pointer;
  text-align: center;
  user-select: none;
  width: 100%;

}

.button-88:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #14a73e98;
}

.button-88:active {
  box-shadow: 0 0.3em 1em -0.5em #14a73e98;
}





/* CSS */
.button-888 {

  align-items: center;
 
  font-weight: 500;
  font-size: 26px;
  padding: 0.7em 1.4em 0.7em 1.1em;
  color: rgb(0, 0, 0);
  background: #ad5389;
  background: linear-gradient(0deg, rgba(20,167,62,1) 0%, rgba(102,247,113,1) 100%);
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #14a73e98;
  letter-spacing: 0.05em;
  border-radius: 100em;
  cursor: pointer;
  text-align: center;
  user-select: none;
 
 

}

.button-888:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #14a73e98;
}

.button-888:active {
  box-shadow: 0 0.3em 1em -0.5em #14a73e98;
}


.bgf{
  background-color: #1140aa;
  color: #fff;
  font-weight: 600;
}















:root {
  --glow-color: hsl(38, 100%, 50%);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.35em 4em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
  background: none;
  perspective: 2em;

  font-size: 1em;
  font-weight: 300;
  letter-spacing: 1em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}
@media screen and (min-width: 1000px){
  .glowing-btn {
    position: relative;
    color: var(--glow-color);
    cursor: pointer;
    padding: 0.35em 20em;
    border: 0.15em solid var(--glow-color);
    border-radius: 0.45em;
    background: none;
    perspective: 2em;
  
    font-size: 1em;
    font-weight: 300;
    letter-spacing: 1em;
  
    -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    animation: border-flicker 2s linear infinite;
  }
  
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn{
    font-size: 1em;
  }
}








.popout {
  /* Set initial styles */
  transition: transform 0.3s ease-in-out;
}

.popout:hover {
  /* Define styles on hover */
  transform: scale(1.1); /* Adjust the scale value as needed */
}


